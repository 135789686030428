import { useAuth, useAuthMutations, useCompanies } from '@api'
import classnames from 'classnames'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { AiOutlineProfile as OrderIcon } from 'react-icons/ai'
import { BiWorld as ChangeLanguageIcon } from 'react-icons/bi'
import { CgLogOut as LogoutIcon } from 'react-icons/cg'
import { FaStoreAlt as DepotIcon, FaTruck as RouteIcon } from 'react-icons/fa'
import { HiOutlineUsers as UsersIcon } from 'react-icons/hi'
import { IoMdArrowBack as BackIcon } from 'react-icons/io'
import { RiMoneyDollarCircleLine as FinancesIcon } from 'react-icons/ri'
import { TbTruckReturn as WaresToDepotIcon } from 'react-icons/tb'
import { Tooltip, Whisper } from 'rsuite'
import LogoIcon from '../../public/icons/logo.svg'

export interface SideBarItemsProps {
  Icon: React.ReactNode
  href: string
  tipText: string
}

const Layout: FC<{
  children: React.ReactNode
  title?: string
}> = ({ title, children }) => {
  const { t } = useTranslation()

  const router = useRouter()

  const [companyId, setCompanyId] = useState<string | null>(null)

  const authQuery = useAuth()
  const authMutations = useAuthMutations()
  const companyQuery = useCompanies()

  useEffect(() => {
    if (authQuery.isIdle) {
      router.replace('/login')
    }
  }, [authQuery.data])

  useEffect(() => {
    setCompanyId(localStorage.getItem('companyId'))
  }, [])

  const company = companyQuery.data?.companiesMap.get(companyId ?? '')

  const getSidebarItems = () => {
    const defaultIconProps = {
      size: 25,
    }

    const items =
      authQuery.isSuccess && companyId && router.asPath !== '/'
        ? [
            {
              Icon: <DepotIcon {...defaultIconProps} />,
              href: `/companies/${companyId}/depots`,
              tipText: t('pages.home.table.depots'),
            },
            {
              Icon: <UsersIcon {...defaultIconProps} />,
              href: `/companies/${companyId}/users`,
              tipText: t('pages.home.table.users'),
            },
            {
              Icon: <OrderIcon {...defaultIconProps} />,
              href: `/companies/${companyId}/orders`,
              tipText: t('pages.home.table.orders'),
            },
            {
              Icon: <RouteIcon {...defaultIconProps} />,
              href: `/companies/${companyId}/routes`,
              tipText: t('pages.home.table.routes'),
            },
            {
              ...(company && !company.simplifiedOrders
                ? {
                    Icon: (
                      <WaresToDepotIcon
                        {...{ ...defaultIconProps, size: 30 }}
                      />
                    ),
                    href: `/companies/${companyId}/waresToDepot`,
                    tipText: t('pages.companies.users.form.wares_to_depot'),
                  }
                : {}),
            },
            {
              ...(company && !company.simplifiedOrders
                ? {
                    Icon: (
                      <FinancesIcon {...{ ...defaultIconProps, size: 30 }} />
                    ),
                    href: `/companies/${companyId}/finances`,
                    tipText: t('pages.companies.users.form.finances'),
                  }
                : {}),
            },
          ]
        : []

    return items.filter(
      item => item.Icon && item.href && item.tipText,
    ) as SideBarItemsProps[]
  }

  return (
    <div className={classnames('grid grid-cols-[80px_minmax(900px,_1fr)]', {})}>
      <aside className="bg-primary h-screen flex flex-col items-center text-neutral-content">
        <Link href="/">
          <a className="bg-white p-2.5 flex items-center justify-center aspect-square rounded-full my-2.5 hover:shadow-2xl">
            <LogoIcon height={35} width={40} />
          </a>
        </Link>

        <ul className="w-full h-full overflow-y-auto overflow-x-clip flex flex-col items-center">
          {getSidebarItems().map(({ Icon, href, tipText }, index) => (
            <Whisper
              key={index}
              preventOverflow
              trigger="hover"
              speaker={<Tooltip>{tipText}</Tooltip>}
              placement="right"
            >
              <li
                onClick={() => router.push(href)}
                className={classnames(
                  'w-[75%] flex justify-center cursor-pointer items-center rounded-full hover:bg-indigo-300 hover:text-primary hover:shadow-2xl aspect-square mt-5',
                )}
              >
                {Icon}
              </li>
            </Whisper>
          ))}
        </ul>

        <Whisper
          preventOverflow
          trigger="hover"
          speaker={<Tooltip>{t('ui.logout')}</Tooltip>}
          placement="right"
        >
          <button
            onClick={() => authMutations.logout.mutate()}
            className="w-[75%] flex justify-center items-center rounded-full hover:bg-indigo-30 hover:shadow-2xl aspect-square my-5"
          >
            <LogoutIcon size={25} />
          </button>
        </Whisper>
      </aside>

      <div>
        <header className="p-4 flex items-center justify-between shadow-sm">
          <div>
            <div className="flex items-center">
              <button
                className="btn btn-ghost mr-4"
                onClick={() => router.back()}
                disabled={router.asPath === '/'}
              >
                <BackIcon size={25} fill="#4443a1" />
              </button>

              {title && (
                <h3 className="font-bold text-xl text-primary">{title}</h3>
              )}
            </div>
          </div>

          <div className="dropdown dropdown-end">
            <label tabIndex={0} className="btn btn-ghost">
              <div className="flex items-center">
                <span className="mr-2">
                  {router.locale === 'uk' ? 'ua' : router.locale}
                </span>
                <ChangeLanguageIcon className="h-5 w-5" />
              </div>
            </label>
            <ul
              tabIndex={0}
              className="dropdown-content menu mt-2 p-2 shadow bg-base-100 rounded-box w-52"
            >
              <li>
                <Link href={router.asPath} locale="en">
                  <a>{t('ui.language.en')}</a>
                </Link>
              </li>
              <li>
                <Link href={router.asPath} locale="ru">
                  <a>{t('ui.language.ru')}</a>
                </Link>
              </li>
              <li>
                <Link href={router.asPath} locale="uk">
                  <a>{t('ui.language.uk')}</a>
                </Link>
              </li>
              <li>
                <Link href={router.asPath} locale="ge">
                  <a>{t('ui.language.ge')}</a>
                </Link>
              </li>
            </ul>
          </div>
        </header>

        <main className="p-5">{children}</main>
      </div>
    </div>
  )
}

export default Layout
