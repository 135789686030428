import type { UseQueryResult } from 'react-query'
import Layout from './Layout'
import Loading from './Loading'

const showLoading = (...queries: UseQueryResult[]) =>
  queries.some(({ isLoading, isIdle }) => isLoading || isIdle)

const showError = (...queries: UseQueryResult[]) =>
  queries.some(({ isError }) => isError)

interface LoaderProps {
  queries: UseQueryResult[]
}

export const Loader = ({ queries }: LoaderProps) => {
  return (
    <Layout>
      <div className="p-8 flex justify-center align-middle">
        {showLoading(...queries) && <Loading />}

        {showError(...queries) && (
          <p className="font-medium text-red-500">Something went wrong</p>
        )}
      </div>
    </Layout>
  )
}

export default Loader
