// @ts-ignore
import loadingAnimation from '@public/animations/loading.json'
import { classnames } from '@tools/common'
import { useLottie } from 'lottie-react'

const Loading = ({ className }: { className?: string }) => {
  const { View } = useLottie({
    animationData: loadingAnimation,
    loop: true,
  })

  return (
    <div
      className={classnames('h-40 flex justify-center items-center', className)}
    >
      {View}
    </div>
  )
}

export default Loading
